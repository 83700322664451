import {initializeApp} from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithCustomToken,
  signInWithEmailLink,
  signInWithPopup,
} from 'firebase/auth';

type Env = 'development' | 'staging' | 'production';
const env = (import.meta.env.MODE as Env) || 'development';

console.log('firebase env', env);

const envConfig = {
  development: {
    apiKey: 'AIzaSyC6g93mUJsIdGCdaEtv1b5PTEID9g2Rkyo',
    authDomain: 'paywong-local.firebaseapp.com',
    projectId: 'paywong-local',
    storageBucket: 'paywong-local.appspot.com',
    messagingSenderId: '433975981013',
    appId: '1:433975981013:web:0c424882e0fe48ff52035c',
    measurementId: 'G-NB672327E8',
  },
  staging: {
    apiKey: 'AIzaSyCrgdjdoxzdOStvaafanb3iCCro3OuX1Mc',
    authDomain: 'paywong-staging.firebaseapp.com',
    projectId: 'paywong-staging',
    storageBucket: 'paywong-staging.appspot.com',
    messagingSenderId: '118452568049',
    appId: '1:118452568049:web:eef2956ff86974732fce50',
    measurementId: 'G-FEQFJ7W3GR',
  },
  production: {
    apiKey: 'AIzaSyAyabhAgdcdNrXwUhS8tXPsnWhh1NJNV6Y',
    authDomain: 'paywong-production.firebaseapp.com',
    projectId: 'paywong-production',
    storageBucket: 'paywong-production.appspot.com',
    messagingSenderId: '810015865945',
    appId: '1:810015865945:web:7bf5f93329c718429ac548',
    measurementId: 'G-R2BZ50MT9C',
  },
};

const app = initializeApp({...envConfig[env]});
const auth = getAuth(app);

const sendEmailLink = async (email: string) =>
  sendSignInLinkToEmail(auth, email, {
    url: `${window.location.origin}/auth/verify`,
    handleCodeInApp: true,
  });

const signEmailLink = async (email: string, link: string) => {
  if (isSignInWithEmailLink(auth, link)) {
    return await signInWithEmailLink(auth, email, link);
  } else {
    throw new Error('Invalid sign in link');
  }
};

const signWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  auth.useDeviceLanguage();
  provider.setCustomParameters({prompt: 'select_account'});
  return await signInWithPopup(auth, provider);
};

const signCustomToken = async (token: string) => {
  return await signInWithCustomToken(auth, token);
};

export {app, auth, sendEmailLink, signCustomToken, signEmailLink, signWithGoogle};
